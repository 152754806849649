<template>
  <div>
    <lottie-player
      src="https://assets4.lottiefiles.com/packages/lf20_x62chJ.json"
      background="transparent"
      speed="1"
      class="mx-auto"
      style="width: 500px; height: 500px"
      loop
      autoplay
    ></lottie-player>
    <h1 class="mx-auto text-center">{{ text }}</h1>
  </div>
</template>

<script>
export default {
  name: 'LinkGenerator',
  data() {
    return {
      text: 'Redirecting ',
      timeout: null,
      loading: false,
    }
  },
  async mounted() {
    this.loading = true
    setTimeout(async () => {
      this.runText()
      setTimeout(async () => {
        this.runText()
        setTimeout(async () => {
          this.runText()
          let data = await this.$store.dispatch('link/updateClickCount', {
            slug: this.$route.params.slug,
            phone_number: this.$route.params.phone,
          })
          //console.log(data)
          if (!data.status) {
            this.text = 'Some Thing Went Wrong. Please Check your link'
          } else {
            window.location.replace(data.url)
          }
        }, 700)
      }, 700)
    }, 700)
  },
  methods: {
    runText() {
      this.text += '.'
    },
    resetText() {
      this.text = 'Redirecting '
    },
  },
}
</script>

<style></style>
